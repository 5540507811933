<template>
  <the-table-page :titleTable="$t('Пользователи')"
                  :tableData="tableData"
                  :load="load"
                  fieldId="userId"
                  :updateData="updateData"
                  :fieldInfoList="fieldInfoList"
                  :searchStart="searchStart"
                  :sortData="sortData"
                  :setSort="setSort"
                  :deleteEl="deleteEl"
                  :prefixEdit="`/user`"
                  :linkCreate="`/user/create`"
                  configName = "users"
                  :roleName="nameRoleEntity"
  ></the-table-page>
</template>

<script>
    import scheme from './scheme.json'
    import TheTablePage from "../../TheTablePage.vue";
    import {ENTITY_NAME} from "./store/type";
    import {NAME_OBJECT} from "../../../RoleConstanans"
    import formatData from "../../../helpers/converters/formatData"
    import {
        SET_MAIN_PAGINATION,
        SET_MAIN_PAGINATION_PER_PAGE,
        SET_MAIN_PAGINATION_PAGE,
        SET_MAIN_SORT_ENTITY,
        SET_MAIN_SEARCH_ENTITY
    } from "../../../../src/store/type"

    import {READ, DELETE} from '../../../store/type'
    import {mapState} from 'vuex';
    import NotificationSample from 'src/pages/Dashboard/Components/NotificationSample';
    import {DEFAULT_PER_PAGE, TYPE_ERRORS, FIELD_LANG_CODE} from '../../../constant'
    import notify from '../../../helpers/notify/notify'
    import convertLangDataToTable from '../../../helpers/converters/convertLangDataToTable'

    export default {
        components: {
            TheTablePage
        },
        data() {
            return {
                scheme: scheme,
                load: false
            }
        },
        computed: {
            ...mapState({
                fieldInfoList: state => state.users.fieldInfoList,
                lang: state => state.common.lang,
                sortData: state => state.pagination.sortData,
            }),
            nameRoleEntity(){
                return NAME_OBJECT.Admin
            },
            tableData() {
                let hasLang = false
                let langNow = this.$store.state.common.lang
                let dataNow = this.$store.state.users.data || []
                if (dataNow && dataNow.length > 0) {
                    dataNow = convertLangDataToTable(dataNow, langNow, FIELD_LANG_CODE, hasLang)
                    dataNow = dataNow.map(item => {
                        return { ...item, isActive: item.isActive?this.$t('Да'):this.$t("Нет"), validDate :  formatData(item.validDate) }
                    })
                    return dataNow
                }
            }
        },
        watch: {
            lang() {
                this.initData()
            }
        },
        methods: {
            getData() {
                this.load = true
                this.$store.dispatch(ENTITY_NAME + READ, {
                    self: this,
                    UserId: this.$route.params.UserId
                }).then(res => {
                    this.load = false
                })
                    .catch(err => {
                        this.load = false
                        notify(this, this.$t('Ошибка получения данных'), TYPE_ERRORS.DANGER)
                    })
            },
            updateData() {
                this.getData()
            },
            searchStart(search) {
                this.$store.commit(SET_MAIN_SEARCH_ENTITY, search)
                this.getData()
            },
            setSort(val) {
                this.$store.commit(SET_MAIN_SORT_ENTITY, val)
                this.getData()
            },
            deleteEl(id) {

                this.$store.dispatch(ENTITY_NAME + DELETE, {self: this, id:id}).then(res => {
                    this.getData()
                       if(res.errors && res.errors.length  == 0){notify(this, this.$t("Успешно"),TYPE_ERRORS.SUCCESS); }
                })
            },
            initData() {
                this.$store.commit(ENTITY_NAME + READ ,{ data: [], fieldInfoList: []})
                this.$store.commit(SET_MAIN_SORT_ENTITY, {})
                this.$store.commit(SET_MAIN_SEARCH_ENTITY, '')
                this.$store.commit(SET_MAIN_PAGINATION_PER_PAGE, DEFAULT_PER_PAGE)
                this.$store.commit(SET_MAIN_PAGINATION_PAGE, 1)
                this.getData()
            }
        },
        created() {
            this.initData()
        }
    }
</script>
